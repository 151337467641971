.po_item {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  position: relative;
  background: var(--secondary-color);
  padding: 6px 12px;
  border: 1px solid var(--secondary-color);
  transition: 0.3s ease;
  min-height: 320px;
  border-radius: 6px;
}

.po_item img {
  height: 100%;
}

.po_item h3, .po_item p {
  color: var(--bg-color);
}

.po_item .content {
  position: absolute;
  height: 0;
  width: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--overlay-color);
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  transition: 0.3s ease-in-out;
  opacity: 0;
  padding: 8px;
  border: 1px solid black;
}

.po_item .content {
  opacity: 0;
  transition-delay: 1s;
  transition: 0.3s ease;
  font-size: 18px;
}

.po_item:hover {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.25);
}

.po_item:hover .content {
  height: calc(100% - 10px);
  width: calc(100% - 10px);
  opacity: 1;
  border-radius: 6px;
  color: var(--secondary-color);
}

.po_item .content a {
  background: var(--bg-color);
  border: solid 1px var(--text-color);
  padding: 4px 8px;
  text-align: center;
  font-size: 1rem;
  text-transform: capitalize;
}

.po_item .content a:hover {
  text-decoration: none;
}

@media screen and (max-width: 425px) {
  .po_item .content {
    font-size: 17px;
  }
} 

@media (max-width: 991.98px) {
  .po_item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .po_item div, .po_item img{
    width: 100%;
  }
}